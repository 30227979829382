<template>
  <div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="consumptions" sort-by="calories"
      class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Consumo Interno</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span>Periodo: de &nbsp <b style="color:black">{{ formatDate(filter.date_start) }}</b>&nbsp até &nbsp <b
              style="color:black">{{ formatDate(filter.date_end) }}</b></span>
        </v-toolbar>
      </template>

      <template v-slot:item.sale_price="{ item }">
        {{ formatMoney(item.sale_price) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDateTime(item.created_at) }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ formatMoney(item.sale_price * item.quantity) }}
      </template>


      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:foot>

      </template>

      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="Filtros" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field v-model="filter.date_start" :error-messages="error.date_start"
                        @change="error.date_start = null" outlined label="Data inicial" type="datetime-local"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field class="ml-2" v-model="filter.date_end" :error-messages="error.date_end"
                        @change="error.date_end = null" outlined label="Data final" type="datetime-local"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />

  </div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiFileExcelOutline,
  mdiFilter,
  mdiOpenInNew,
  mdiPencilOutline,
  mdiPlus,
  mdiTools
} from '@mdi/js'
import {
  mapActions
} from 'vuex'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog
  },
  data: () => ({
    dialogFilter: false,
    consumptions: [],
    loadingTable: false,
    options: {
      itemsPerPage: 15,
      page: 1,
    },
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    loadingFilter: false,
    filter: {
      date_start: '',
      date_end: '',
    },
    headers: [
      {
        text: 'CAIXA',
        align: 'start',
        value: 'cash_id',
      },
      {
        text: 'CONSUMIDOR',
        align: 'start',
        value: 'consumer',
      },
      {
        text: 'PRODUTO',
        align: 'start',
        value: 'product.description',
      },
      {
        text: 'DATA/HORA',
        align: 'start',
        value: 'created_at',
      },
      {
        text: 'QUANTIDADE',
        align: 'center',
        value: 'quantity',
      },
      {
        text: 'VALOR',
        align: 'end',
        value: 'sale_price',
      },
      {
        text: 'TOTAL',
        align: 'end',
        value: 'total',
      },


    ],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    error: {
      date_start: null,
      date_end: null,
    },
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter
    },
  }),

  computed: {

    newTab() {
      let url_atual = window.location.href;
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },
    bagFilter() {
      return Object.keys(this.filter).length
    },


  },
  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },

  },

  created() {
    this.periodInitial();
    this.initialize();
    
  },

  methods: {
    ...mapActions('intern_consumptions', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true

      const { page, itemsPerPage } = this.options
      
      const payload = {
        items_per_page: itemsPerPage || 15,
        page,
        date_start: this.filter.date_start,
        date_end: this.filter.date_end,
      }

      this.getAll(payload)
        .then(response => {
          this.consumptions = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loadingTable = false
          this.loadingFilter = false
          this.dialogFilter = false
          
        })
    },
    clearFilter() {
      this.filter.date_start = ''
      this.filter.date_end = ''
    },

    periodInitial() {
      let dateToday = new Date();
      dateToday.setHours(dateToday.getHours() - 3);

      this.filter.date_end = dateToday.toISOString().slice(0, 10) + 'T' + '23:59'

      dateToday.setDate(dateToday.getDate() - 30);

      this.filter.date_start = dateToday.toISOString().slice(0, 10) + 'T' + '00:00'
    },
    confirmFilter() {
      //validar filtro periodo

      if (!this.filter.date_start) {
        this.error.date_start = 'Campo obrigatório'
        return false
      }
      if (!this.filter.date_end) {
        this.error.date_end = 'Campo obrigatório'
        return false
      }
      this.loadingFilter = true
      this.initialize()
    },

    qtdGuests(item) {
      if (item.current_accommodation) {
        return item.current_accommodation.guest.length
      }

      return 0
    },
    sendExportExcel() {
      
      const payload = {
        date_start: this.filter.date_start,
        date_end: this.filter.date_end,
      }
      
      this.exportExcel(payload)
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();

        }).catch((response) => {
         
        }).finally(() => {

        })


    },

    formatDateTime(date) {
      if (date) {
        const result = new Date(date);
        const formatedDate = result.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
        const time = result.getHours() + ':' + result.getMinutes()

        return formatedDate + ' às ' + time
      }

    },
    formatDate(date) {
      if (date) {
        const result = new Date(date);
        return result.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      }

    },

    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },

  },


}
</script>
